import { isDEStore } from '../helpers';
import { loggerService } from "./logger.service";

const defaultServiceWorkerURL: string = '/contorion-sw.js';
const SHOULD_REGISTER_CONTORION_SW = true;

class ServiceWorkerService {
    protected serviceWorker: ServiceWorkerRegistration = null;

    public get serviceWorkerRegisteration(): ServiceWorkerRegistration {
        return this.serviceWorker;
    }

    public registerServiceWorker(serviceWorkerURL = defaultServiceWorkerURL)
        : Promise<void | ServiceWorkerRegistration> {
        if (!this.isServiceWorkerSupported() || !isDEStore() || !SHOULD_REGISTER_CONTORION_SW) {
            return;
        }

        return navigator.serviceWorker.register(serviceWorkerURL)
            .then(async (registration) => {

                this.serviceWorker = registration;
                return registration;
            })
            .catch((err) => {
                loggerService.error(err, "service worker register error");
            });
    }

    public unregisterServiceWorkers() {
        if (!this.isServiceWorkerSupported() || !isDEStore()) {
            return;
        }

        navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (let registration of registrations) {
                registration.unregister().then((success) => {
                    if (!success){
                        loggerService.error('Failed to unregister Service Worker');
                    }
                });
            }
        });
    }

    public isServiceWorkerSupported(): boolean {
        return 'serviceWorker' in navigator;
    }
}

export const serviceWorkerService = new ServiceWorkerService();
