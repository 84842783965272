import { Component, componentService } from '@contorion/core';

componentService.register('.js-collapsible', (element: Element) => {
    import (/* webpackChunkName: "configurator" */ './components/collapsible.component')
        .then(({ CollapsibleComponent }) => {
            <Component> CollapsibleComponent.loadByElement(element);
        });
});

componentService.register('.js-bulk-price-collapse', (element: Element) => {
    import (/* webpackChunkName: "configurator" */ './components/bulkPrice.component')
        .then(({ BulkPriceComponent }) => {
            <Component> BulkPriceComponent.loadByElement(element);
        });
});
