import { Component, componentService, services } from '@contorion/core';

componentService.register('.js-vivian-event-tracker', (element: Element) => {
    import (/* webpackChunkName: "vivian-event-tracker" */
        './components/vivian-event-tracker.component')
        .then(({VivianEventTrackerComponent}) => {
            <Component> VivianEventTrackerComponent.loadByElement(element);
        });
});

componentService.register('.js-vivian-selection-tracker', (element: Element) => {
    import (/* webpackChunkName: "vivian-selection-tracker" */
        './components/vivian-selection-tracker.component')
        .then(({VivianSelectionTrackerComponent}) => {
            <Component> VivianSelectionTrackerComponent.loadByElement(element);
        });
});

componentService.register('.js-vivian-banners-tracker', (element: Element) => {
    import (/* webpackChunkName: "vivian-homepage-banners-tracker" */
        './components/vivian-homepage-banners-tracker.component')
        .then(({VivianHomepageBannersTrackerComponent}) => {
            <Component> VivianHomepageBannersTrackerComponent.loadByElement(element);
        });
});

services.register('vivian-service', () => {
    return import (
        /* webpackChunkName: "vivian-service" */
        './services/vivian.service')
        .then(({ vivianService }) => {
            return vivianService;
        });
});

services.register('sponsored-product-impression-service', () => {
    return import (
        /* webpackChunkName: "sponsored-product-impression-service" */
        './services/sponsored-product-impression.service')
        .then(({ sponsoredProductImpressionService }) => {
            return sponsoredProductImpressionService;
        });
});

componentService.register('.js-impression-tracker', (element: Element) => {
    import (
        /* webpackChunkName: "impression-tracker" */ './components/vivian-impression-tracker.component'
        ).then(({ VivianImpressionTrackerComponent }) => {
        <Component> VivianImpressionTrackerComponent.loadByElement(element);
    });
});

services.register('product-impressions-service', () => {
    return import (
        /* webpackChunkName: "product-impressions-service" */
        './services/product-impressions.service')
        .then(({ productImpressionsService }) => {
            return productImpressionsService;
        });
});
