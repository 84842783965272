import { Component, componentService, services } from '@contorion/core';

componentService.register('.js-config-simple-selectors', (element: Element) => {
    import (
        /* webpackChunkName: "config-simple-selectors" */
        './components/config-simple-selectors.component')
        .then(({ ConfigSimpleSelectorsComponent }) => {
            <Component> ConfigSimpleSelectorsComponent.loadByElement(element);
        });
});

componentService.register('.js-config-simple-selectors-v2', (element: Element) => {
    import (
        /* webpackChunkName: "config-simple-selectors-v2" */
        './components/config-simple-selectors-v2.component')
        .then(({ ConfigSimpleSelectorsV2Component }) => {
            <Component> ConfigSimpleSelectorsV2Component.loadByElement(element);
        });
});

componentService.register('.js-product-history', (element: Element) => {
    import (/* webpackChunkName: "productHistory" */ './components/product-history.component')
        .then(({ ProductHistoryComponent }) => {
            <Component> ProductHistoryComponent.loadByElement(element);
        });
});

componentService.register('.js-product-image-preview', (element: Element) => {
    import (
        /* webpackChunkName: "product-image" */
        './components/image.component')
        .then(({ ImageComponent }) => {
            <Component> ImageComponent.loadByElement(element);
        });
});

componentService.register('.js-product-image-thumbnail', (element: Element) => {
    import (
        /* webpackChunkName: "product-image-thumbnail" */
        './components/image-thumbnail.component')
        .then(({ ImageThumbnailComponent }) => {
            <Component> ImageThumbnailComponent.loadByElement(element);
        });
});

componentService.register('.js-image-navigation', (element: Element) => {
    import (
        /* webpackChunkName: "product-image-thumbnail" */
        './components/image-navigation.component')
        .then(({ ImageNavigationComponent }) => {
            <Component> ImageNavigationComponent.loadByElement(element);
        });
});

componentService.register('.js-image-navigation-v2', (element: Element) => {
    import (
        /* webpackChunkName: "product-image-thumbnail" */
        './components/image-navigation-v2.component')
        .then(({ ImageNavigationComponentV2 }) => {
            <Component> ImageNavigationComponentV2.loadByElement(element);
        });
});

componentService.register('.js-open-overlay', (element: Element) => {
    import (
        /* webpackChunkName: "product-image-overlay-button" */
        './components/image-overlay-button.component')
        .then(({ ImageOverlayButtonComponent }) => {
            <Component> ImageOverlayButtonComponent.loadByElement(element);
        });
});

componentService.register('.js-product-image-overlay', (element: Element) => {
    import (
        /* webpackChunkName: "product-image-overlay" */
        './components/image-overlay.component')
        .then(({ ImageOverlayComponent }) => {
            <Component> ImageOverlayComponent.loadByElement(element);
        });
});

componentService.register('.js-product-image-overlay-mobile', (element: Element) => {
    import (
        /* webpackChunkName: "product-image-overlay-mobile" */
        './components/image-overlay-mobile.component')
        .then(({ ImageOverlayMobileComponent }) => {
            <Component> ImageOverlayMobileComponent.loadByElement(element);
        });
});

componentService.register('.js-packagesize', (element: Element) => {
    import (
        /* webpackChunkName: "size-selector" */
        './components/size-selector.component')
        .then(({ SizeSelectorComponent }) => {
            <Component> SizeSelectorComponent.loadByElement(element);
        });
});

componentService.register('.js-product-detail', (element: Element) => {
    import (
        /* webpackChunkName: "product-detail" */
        './components/product-detail.component')
        .then(({ ProductDetailComponent }) => {
            <Component> ProductDetailComponent.loadByElement(element);
        });
});

componentService.register('.js-product-gallery-counter', (element: Element) => {
    import (
        /* webpackChunkName: "product-gallery-counter" */
        './components/product-gallery-counter.component')
        .then(({ ProductGalleryCounterComponent }) => {
            <Component> ProductGalleryCounterComponent.loadByElement(element);
        });
});

services.register('image-service', () => {
    return import (/* webpackChunkName: "image-service" */ './services/image.service')
        .then(({ imageService }) => {
            return imageService;
        });
});

services.register('image-overlay-service', () => {
    return import (
        /* webpackChunkName: "image-overlay-service" */
        './services/image-overlay.service')
        .then(({ imageOverlayService }) => {
            return imageOverlayService;
        });
});

services.register('size-selector-service', () => {
    return import (
        /* webpackChunkName: "size-selector-service" */
        './services/size-selector.service')
        .then(({ sizeSelectorService }) => {
            return sizeSelectorService;
        });
});

services.register('product-service', () => {
    return import (
        /* webpackChunkName: "product-service" */
        './services/product.service')
        .then(({ productService }) => {
            return productService;
        });
});

componentService.register('.js-paypal-banner', (element: Element) => {
    import (
        /* webpackChunkName: "paypal-banner" */
        './components/paypal-banner.component')
        .then(({ PaypalBannerComponent }) => {
            <Component> PaypalBannerComponent.loadByElement(element);
        });
});

componentService.register('.js-product-bundle-image', (element: Element) => {
    import (
        /* webpackChunkName: "product-bundle-image" */
        './components/product-bundle-image.component')
        .then(({ ProductBundleImageComponent }) => {
            <Component> ProductBundleImageComponent.loadByElement(element);
        });
});

componentService.register('.js-product-bundle-thumb', (element: Element) => {
    import (
        /* webpackChunkName: "product-bundle-thumb" */
        './components/product-bundle-thumb.component')
        .then(({ ProductBundleThumbComponent }) => {
            <Component> ProductBundleThumbComponent.loadByElement(element);
        });
});
